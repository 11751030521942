<template>
    <component :is="currentCardComponent"
               :deviceObject="deviceObject"
               :timeseries="timeseries"
               :isCurrentUserDevice="isCurrentUserDevice"
               @onLoad="() => this.loading = false"
               @deviceDoUpdate="$emit('deviceDoUpdate', $event)"
               @deviceDoShowGeoPosition="$emit('deviceDoShowGeoPosition', $event)"
               @deviceDoRemove="$emit('deviceDoRemove', $event)"/>
</template>

<script>
import DoubleRelayCard_1 from "./DoubleRelayCard_1";
import DoubleRelayCard_2 from "./DoubleRelayCard_2";
import DoubleRelayCard_3 from "./DoubleRelayCard_3";
import AbstractDefaultCard from "../default/AbstractDefaultCard";

export default {
    name: "AbstractDoubleRelayCard",
    extends: AbstractDefaultCard,
    components: {
        DoubleRelayCard_1,
        DoubleRelayCard_2,
        DoubleRelayCard_3,
    },
    computed: {
        currentCardComponent() {
            switch (this.currentCardDesign) {
                case 3:
                    return DoubleRelayCard_3;
                case 2:
                    return DoubleRelayCard_2;
                default:
                    return DoubleRelayCard_1
            }
        },
    }
}
</script>
