<script>

import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_2 from "../default/DefaultCard_2";
import DoubleRelayCard_1Table from "./DoubleRelayCard_1Table";
import DoubleRelayCard_2LatestValue from "./DoubleRelayCard_2LatestValue";
import DoubleRelayCard_2Button from "./DoubleRelayCard_2Button";

export default {
    name: "DoubleRelayCard_2",
    extends: DefaultCard_2,
    components: {
        HeaderComponent: DefaultCard_1Header,
        LatestValueComponent: DoubleRelayCard_2LatestValue,
        MetricsButtonsComponent: DoubleRelayCard_2Button,
        ChartComponent: DoubleRelayCard_1Table,
    },
}
</script>
