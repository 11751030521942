<script>

import DoubleRelayCard_1LatestValue from "./DoubleRelayCard_1LatestValue";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_1 from "../default/DefaultCard_1";
import DoubleRelayCard_1Table from "./DoubleRelayCard_1Table";
import DoubleRelayCard_1Button from "./DoubleRelayCard_1Button";

export default {
    name: "DoubleRelayCard_1",
    extends: DefaultCard_1,
    components: {
        HeaderComponent: DefaultCard_1Header,
        LatestValueComponent: DoubleRelayCard_1LatestValue,
        MetricsButtonsComponent: DoubleRelayCard_1Button,
        ChartComponent: DoubleRelayCard_1Table,
    },
}
</script>
