<template>
    <div class="d-flex justify-content-around metric py-2">
        <div v-for="(metric, index) in paginatedData"
             :key="index"
             class="metric-buttons d-flex align-items-center justify-content-center flex-column"
             :class="{ active : isSelected(metric) }"
             @click="$emit('change', metric)">
            <Icon :icon-name="+getMetricLabel(metric) === 1 ? 'double_relay' : 'double_relay_off'"
                  v-if="metric.getSlug() === 'relay1' || metric.getSlug() === 'relay2'"
                  width="24"
                  height="24"
                  :fill="metric.getColor()"
                  viewBox="0 0 26 26"/>
            <Icon icon-name="energy"
                  v-if="metric.getSlug() === 'bat_percent'"
                  width="24"
                  height="24"
                  :fill="metric.getColor()"
                  viewBox="0 0 26 26"/>
            <div class="position-relative">
            <span v-if="metric.getPortIndex() !== null" class="metric-icon-index">
                {{ metric.getPortIndex() + 1 }}
            </span>

                <span class="metric-data py-1"
                      :style="isSelected(metric) ? `color: ${metric.getColor()}` : 'color: #706C6C'">
                <span v-if="metric.getSlug() === 'relay1' || metric.getSlug() === 'relay2'">
                    {{ +getMetricLabel(metric) === 1 ? $t('ВКЛ') : $t('ВЫКЛ') }}
                </span>
                <span v-else>
                    &nbsp;
                </span>

            </span>
            </div>

            <Icon class="d-block mx-auto"
                  v-if="isSelected(metric)"
                  icon-name="dot"
                  :fill=metric.getColor()
                  width="7"
                  height="7"/>

            <div v-else style="height: 7px"></div>
        </div>
    </div>
</template>
<script>

    import DefaultCard_2MetricsButtons from "../default/DefaultCard_2MetricsButtons";

    export default {
        name: "DoubleRelayCard_2Button",
        extends: DefaultCard_2MetricsButtons,
    }
</script>
