<template>
    <div class="w-100">
        <div class="btn-group d-flex justify-content-between align-items-center">
            <div v-for="(metric, index) in paginatedData"
                 :key="index"
                 :class="{ active : isSelected(metric) }"
                 @click="$emit('change', metric)"
                 class="btn px-2 py-1">
                    <span class="position-relative">
                        <span v-if="metric.getPortIndex() !== null" class="metric-icon-index">
                            {{ metric.getPortIndex() + 1 }}
                        </span>
                        <Icon :icon-name="+getMetricLabel(metric) === 1 ? 'double_relay' : 'double_relay_off'"
                              v-if="metric.getSlug() === 'relay1' || metric.getSlug() === 'relay2'"
                              width="24"
                              height="24"
                              :fill="metric.getColor()"
                              viewBox="0 0 26 26"/>
                        <Icon icon-name="energy"
                              v-if="metric.getSlug() === 'bat_percent'"
                              width="24"
                              height="24"
                              :fill="metric.getColor()"
                              viewBox="0 0 26 26"/>
                    </span>

                <Icon class="d-block mx-auto mt-1"
                      v-if="isSelected(metric)"
                      :fill="metric.getColor()"
                      icon-name="dot"
                      width="4"
                      height="4"/>
                <div class="mt-1" v-else style="height: 4px"></div>
            </div>
        </div>
    </div>
</template>

<script>

import DefaultCard_1MetricsButtons from "../default/DefaultCard_1MetricsButtons";

export default {
    name: "DoubleRelayCard_1Button",
    extends: DefaultCard_1MetricsButtons,
    methods: {
        getMetricLabel(metric, fixed = 2) {
            const values = this.timeseries?.filter(item => item.getSlug() === metric?.getSlug());

            const value = metric?.getPortIndex()
                ? values[metric?.getPortIndex()]
                : values[0];

            const result = value?.getLatestData()?.getValue();

            return (result || result === 0) ? result?.toFixed(fixed) + " " + metric.getUnits() : "N/A";
        },
    }

}

</script>

