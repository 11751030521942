<template>
    <div ref="latestValue" class="latest-value animate__animated">
        <div class="latest-value__bg text-center" :style="metricImage">
            <div v-if="value !== null">
                <button
                    v-if="currentMetric.getSlug() === 'relay1'"
                    v-confirm="sendCommand" :disabled="processingRelay2||processingRelay1"
                    class="btn btn-sm bg-white"
                    :class="+value === 1 ? 'text-danger' : 'text-success'">
                    <div v-if="processingRelay1" class="d-flex align-items-center justify-content-center">
                        <div class="spinner-border spinner-border-sm mr-1" role="status">
                            <span class="sr-only"></span>
                        </div>
                        <div>
                            {{ $t('Выполнение...') }} {{ counter }}
                        </div>
                    </div>
                    <div v-else>
                        <Icon :icon-name="+value === 1 ? 'double_relay' : 'double_relay_off'"
                              width="24"
                              height="24"
                              :fill="currentMetric.getColor()"
                              viewBox="0 0 26 26"/>

                        {{ +value === 1 ? $t('Выключить') : $t('Включить') }}
                    </div>
                </button>
                <button
                    v-if="currentMetric.getSlug() === 'relay2'"
                    v-confirm="sendCommand" :disabled="processingRelay2||processingRelay1"
                    class="btn btn-sm bg-white"
                    :class="+value === 1 ? 'text-danger' : 'text-success'">
                    <div v-if="processingRelay2" class="d-flex align-items-center justify-content-center">
                        <div class="spinner-border spinner-border-sm mr-1" role="status">
                            <span class="sr-only"></span>
                        </div>
                        <div>
                            {{ $t('Выполнение...') }} {{ counter }}
                        </div>
                    </div>
                    <div v-else>
                        <Icon :icon-name="+value === 1 ? 'double_relay' : 'double_relay_off'"
                              width="24"
                              height="24"
                              :fill="currentMetric.getColor()"
                              viewBox="0 0 26 26"/>
                        {{ +value === 1 ? $t('Выключить') : $t('Включить') }}
                    </div>
                </button>
                <div v-if="currentMetric.getSlug() === 'bat_percent'">
                    <Icon icon-name="energy"
                          width="50"
                          height="50"
                          :fill="currentMetric.getColor()"
                    />
                </div>
            </div>
            <div v-else>
                <div class="latest-value__value">N/A</div>
            </div>
        </div>
    </div>
</template>

<script>

import DefaultCard_1LatestValue from "../default/DefaultCard_1LatestValue";
import DownlinkService from "../../../services/DownlinkService";
import TemporaryOption from "@/classess/TemporaryOption";
import Icon from "../../Icon";

const Downlinks = new DownlinkService();
const TempOption = new TemporaryOption();

export default {
    name: "DoubleRelayCard_1LatestValue",
    components: {Icon},
    extends: DefaultCard_1LatestValue,
    data() {
        return {
            processingRelay1: false,
            processingRelay2: false,
            counter: null,
            loop: null,
            tempOptionNameRelay1: "relay1",
            tempOptionNameRelay2: "relay2",
            delay: 10000,
        }
    },
    methods: {
        async sendCommand() {
            this.counter = 10;
            let command;
            if (this.value === null) {
                this.$toast.info(this.$t('Устройство отключенно'));
                return;
            }
            if (this.metricTimeseries.getSlug() === 'relay1') {
                this.processingRelay1 = true;
                TempOption.set(this.tempOptionNameRelay1, true, this.delay);
                command = +this.value === 1 ? 'rele_1_off' : 'rele_1_on';
            }
            if (this.metricTimeseries.getSlug() === 'relay2') {
                this.processingRelay2 = true;
                TempOption.set(this.tempOptionNameRelay2, true, this.delay);
                command = +this.value === 1 ? 'rele_2_off' : 'rele_2_on';
            }

            const result = await Downlinks.sendCommand(this.deviceObject?.getId(), command);

            if (result) {
                this.$toast.info(this.$t('Команда отправлена'));
            } else {
                this.$toast.error(this.$t('Ошибка!'));
                TempOption.remove(this.tempOptionNameRelay1);
                TempOption.remove(this.tempOptionNameRelay2);
            }
            this.done(this.counter)
        },
        done(counter) {
            this.counter = counter;
            this.loop = setInterval(() => {
                this.counter--;

                if ( this.counter <= 0 ) {
                    TempOption.remove(this.tempOptionNameRelay1);
                    TempOption.remove(this.tempOptionNameRelay2);
                    clearInterval(this.loop);
                    this.processingRelay1 = false;
                    this.processingRelay2 = false;
                    this.$eventBus.emit(this.$eventBus.REFRESH_CARD_TIMESERIES, this.deviceObject.getDevEui());
                    this.$toast.success(this.$t('Команда выполнена'));
                }
            }, 1000);
        }
    },
    mounted() {
        const isProcessingRelay1 = TempOption.get(this.tempOptionNameRelay1, false);
        const isProcessingRelay2 = TempOption.get(this.tempOptionNameRelay2, false);
        const optionTtlRelay1 = TempOption.getTtl(this.tempOptionNameRelay1);
        const optionTtlRelay2 = TempOption.getTtl(this.tempOptionNameRelay2);

        if (isProcessingRelay1) {
            this.processingRelay1 = true;
            this.done(Math.floor(optionTtlRelay1 / 1000));
        }
        if (isProcessingRelay2) {
            this.processingRelay2 = true;
            this.done(Math.floor(optionTtlRelay2 / 1000));
        }

    }
}
</script>

<style lang="less" scoped>
@import "~animate.css";

.latest-value {
    width: 100%;
    padding: 0;

    &__bg {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 170px;
        height: 170px;
        background-color: #ccc;
        border-radius: 100%;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__value {
        text-align: center;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1;
        color: #fff;
    }

    &__units {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        color: #fff;
    }

    .status-icon {
        background-color: white;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
    }
}
</style>
