<script>

import Icon from "../../Icon";
import DefaultCard_1Header from "../default/DefaultCard_1Header";
import DefaultCard_3 from "../default/DefaultCard_3";
import DoubleRelayCard_1Table from "./DoubleRelayCard_1Table";
import DoubleRelayCard_3ValuesList from "./DoubleRelayCard_3ValuesList";

export default {
    name: "DoubleRelayCard_3",
    extends: DefaultCard_3,
    components: {
        HeaderComponent: DefaultCard_1Header,
        ValuesListComponent: DoubleRelayCard_3ValuesList,
        ChartComponent: DoubleRelayCard_1Table,
        Icon
    },
}
</script>
<style scoped lang="less">
    .empty {
        display: none;
    }
</style>
