<template>
    <div>
        <div class="row mb-2" style="min-height: 140px">
            <div class="col-12 position-relative">
                <div class="list-group">
                    <div class="list-group-item cursor-pointer px-2 list-group-item-action font-weight-bold justify-content-between align-items-center"
                         v-for="(metric, index) in paginatedData"
                         :key="index"
                         :class="{ active : isSelected(metric) }"
                         @click="$emit('change', metric)">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <div class="pr-2">
                                <div class="position-relative pr-1">
                                <span v-if="metric.getPortIndex() !== null" class="metric-icon-index">
                                    {{ metric.getPortIndex() + 1 }}
                                </span>

                                    <Icon
                                        :icon-name="+getMetricLabel(metric) === 1 ? 'double_relay' : 'double_relay_off'"
                                        v-if="metric.getSlug() === 'relay1' || metric.getSlug() === 'relay2'"
                                        width="23"
                                        height="23"
                                        :fill="metric.getColor()"/>
                                    <Icon icon-name="energy"
                                          v-if="metric.getSlug() === 'bat_percent'"
                                          width="24"
                                          height="24"
                                          :fill="metric.getColor()"
                                          viewBox="0 0 26 26"/>
                                </div>

                                <Icon v-if="isSelected(metric)" class="d-block mx-auto mt-1"
                                      icon-name="dot"
                                      :fill="isSelected(metric) ? metric.getColor() : ''"
                                      width="4"
                                      height="4"/>

                                <div v-else class="mt-1" style="height: 4px"></div>
                            </div>

                            <div v-if="metric.getName().length > 20"
                                 class="list-group-item-title"
                                 data-toggle="tooltip"
                                 :title="metric.getName()">
                                {{ stringLimit(metric.getName(), 20) }}
                            </div>
                            <div v-else class="list-group-item-title">
                                {{ metric.getName() }}
                            </div>
                            <button
                                v-confirm="() => sendCommand(metric)"
                                v-if="isSelected(metric) && metric.getSlug() === 'relay1' || isSelected(metric) && metric.getSlug() === 'relay2'"
                                :disabled="processingRelay1||processingRelay2"
                                class="btn btn-sm bg-white"
                                :class="+getMetricLabel(metric) === 1 ? 'text-danger' : 'text-success'">
                                <div v-if="processingRelay1" class="d-flex align-items-center justify-content-center">
                                    <div class="spinner-border spinner-border-sm mr-1" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                    <div>
                                        {{ $t('Выполнение...') }} {{ counter }}
                                    </div>
                                </div>
                                <div v-else>
                                    <Icon :icon-name="+getMetricLabel(metric) === 1 ? 'double_relay' : 'double_relay_off'"
                                          width="24"
                                          height="24"
                                          :fill="metric.getColor()"
                                          viewBox="0 0 26 26"/>
                                    {{ +getMetricLabel(metric) === 1 ? $t('Выключить') : $t('Включить') }}
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="empty mb-3" style="height: 25px"></div>
    </div>
</template>

<script>

import DefaultCard_3ValuesList from "../default/DefaultCard_3ValuesList";
import DoubleRelayCard_3Button from "./DoubleRelayCard_3Button";

import DownlinkService from "../../../services/DownlinkService";
import TemporaryOption from "@/classess/TemporaryOption";
import Icon from "../../Icon";

const Downlinks = new DownlinkService();
const TempOption = new TemporaryOption();


export default {
    name: "DoubleRelayCard_3ValuesList",
    extends: DefaultCard_3ValuesList,
    components: {
        DoubleRelayCard_3Button,
        Icon,
    },
    data() {
        return {
            processingRelay1: false,
            processingRelay2: false,
            counter: null,
            loop: null,
            tempOptionNameRelay1: "relay1",
            tempOptionNameRelay2: "relay2",
            delay: 10000,
        }
    },
    methods: {
        async sendCommand(metric) {
            this.counter = 10;
            let command;
            if (this.getMetricLabel(metric) === null) {
                this.$toast.info(this.$t('Устройство отключенно'));
                return;
            }
            if (this.currentMetric.getSlug() === 'relay1') {
                this.processingRelay1 = true;
                TempOption.set(this.tempOptionNameRelay1, true, this.delay);
                command = +this.getMetricLabel(metric) === 1 ? 'rele_1_off' : 'rele_1_on';
            }
            if (this.currentMetric.getSlug() === 'relay2') {
                this.processingRelay2 = true;
                TempOption.set(this.tempOptionNameRelay2, true, this.delay);
                command = +this.getMetricLabel(metric) === 1 ? 'rele_2_off' : 'rele_2_on';
            }

            const result = await Downlinks.sendCommand(this.deviceObject?.getId(), command);

            if (result) {
                this.$toast.info(this.$t('Команда отправлена'));
            } else {
                this.$toast.error(this.$t('Ошибка!'));
                TempOption.remove(this.tempOptionNameRelay1);
                TempOption.remove(this.tempOptionNameRelay2);
            }
            this.done(this.counter)
        },
        done(counter) {
            this.counter = counter;
            this.loop = setInterval(() => {
                this.counter--;

                if (this.counter <= 0) {
                    TempOption.remove(this.tempOptionNameRelay1);
                    TempOption.remove(this.tempOptionNameRelay2);
                    clearInterval(this.loop);
                    this.processingRelay1 = false;
                    this.processingRelay2 = false;
                    this.$eventBus.emit(this.$eventBus.REFRESH_CARD_TIMESERIES, this.deviceObject.getDevEui());
                    this.$toast.success(this.$t('Команда выполнена'));
                }
            }, 1000);
        }
    },
    mounted() {
        const isProcessingRelay1 = TempOption.get(this.tempOptionNameRelay1, false);
        const isProcessingRelay2 = TempOption.get(this.tempOptionNameRelay2, false);
        const optionTtlRelay1 = TempOption.getTtl(this.tempOptionNameRelay1);
        const optionTtlRelay2 = TempOption.getTtl(this.tempOptionNameRelay2);

        if (isProcessingRelay1) {
            this.processingRelay1 = true;
            this.done(Math.floor(optionTtlRelay1 / 1000));
        }
        if (isProcessingRelay2) {
            this.processingRelay2 = true;
            this.done(Math.floor(optionTtlRelay2 / 1000));
        }
    }
}
</script>

<style lang="less" scoped>

@import "../../../less/_variables";

.list-group {
    border-radius: 0;

    &-item {
        display: flex;
        border-color: transparent;
        padding: 0.45rem 1.25rem;
        font-size: 0.9rem;
        border-radius: .5rem;
        min-height: 57px;

        &:hover {
            background: white;
        }

        &-title {
            font-weight: 500;
            color: #495057;
        }

        &-value {
            font-weight: 600;
            color: #706C6C;
        }

        &.active {
            color: #495057;
            background: #f1f1f1;
        }
    }
}

</style>
